
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	@Component
	export default class extends Vue {
		@Inject() public readonly alert!: AlertMethod;

		@Prop(String) public url!: string;

		protected loaded: boolean = false;

		private timeout!: number;

		mounted(): void {
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			this.timeout = window.setTimeout(() => this.err(), 5000);
		}

		protected ok(): void {
			this.loaded = true;
			window.clearTimeout(this.timeout);
		}

		protected async err(): Promise<void> {
			await this.alert("無法連線至 LeaPA 主機，\n請再次檢查 Wifi 設定。", "", 'error');
			this.$router.back();
		}
	}
